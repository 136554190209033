<template>
  <div
    class="custom-suggestions"
    :class="{ 'custom-suggestions--invalid': error, 'custom-suggestions--disabled': disabled }"
  >
    <CustomInput
      ref="input"
      v-model="query"
      v-bind="$attrs"
      type="text"
      autocomplete="false"
      class="custom-suggestions__input"
      :class="extendedOptions.inputClass"
      :placeholder="extendedOptions.placeholder"
      :disabled="disabled"
      :error="error"
      float
      @keydown="onKeyDown"
      @blur="hideItems"
      @focus="showItems = true"
    >
      {{ title }}
    </CustomInput>
    <div v-if="items && items.length > 0 && showItems === true" class="suggestions" @mousedown.prevent>
      <ul class="items">
        <li
          v-for="(item, index) in items"
          :key="item[keyField] || index"
          ref="item"
          class="item"
          :class="{ 'is-active': index === activeItemIndex }"
          @click.stop.prevent="selectItem(index)"
        >
          <slot name="item" :item="item">
            {{ item }}
          </slot>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CustomInput from '~/components/elements/CustomInput';

export default {
  name: 'CustomSuggestion',
  components: {CustomInput},
  props: {
    value: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    sync: {
      type: Boolean,
      default: true,
    },
    keyField: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    const defaultOptions = {
      debounce: 0,
      placeholder: '',
      inputClass: 'input',
    };

    const extendedOptions = Object.assign({}, defaultOptions, this.options);

    return {
      extendedOptions,
      query: this.value,
      lastSetQuery: null,
      activeItemIndex: -1,
      showItems: false,
    }
  },
  watch: {
    query(newValue, oldValue) {
      if (newValue === this.lastSetQuery) {
        this.lastSetQuery = null;
        return;
      }
      this.$emit('input', newValue);
    },
    value(newValue) {
      this.setInputQuery(newValue);
    },
    items() {
      this.activeItemIndex = -1;
      this.showItems = true;
    },
  },
  mounted() {
    this.$emit('mounted', this.$refs.input)
  },
  methods: {
    hideItems() {
      setTimeout(() => {
        this.showItems = false;
        if (this.sync) this.setInputQuery(this.value);
      }, 0);
    },
    setInputQuery(value) {
      this.lastSetQuery = value;
      this.query = value;
    },
    onKeyDown(e) {
      this.$emit('keyDown', e.keyCode);
      switch (e.keyCode) {
        case 40:
          this.highlightItem('down');
          e.preventDefault();
          break;
        case 38:
          this.highlightItem('up');
          e.preventDefault();
          break;
        case 13:
          this.selectItem();
          e.preventDefault();
          break;
        case 27:
          this.showItems = false;
          e.preventDefault();
          break;
        default:
          return true;
      }
    },
    selectItem(index) {
      let item = null;
      if (typeof index === 'undefined') {
        if (this.activeItemIndex === -1) return;
        item = this.items[this.activeItemIndex];
      } else item = this.items[index];

      if (!item) return;
      this.$emit('selected', item);
      this.hideItems();
    },
    highlightItem(direction) {
      if (this.items.length === 0) return;

      let selectedIndex = this.items.findIndex((item, index) => index === this.activeItemIndex);
      if (selectedIndex === -1) {
        // nothing selected
        if (direction === 'down') selectedIndex = 0;
        else selectedIndex = this.items.length - 1;
      } else {
        this.activeIndexItem = 0;
        if (direction === 'down') {
          selectedIndex += 1;
          if (selectedIndex === this.items.length) selectedIndex = 0;
        } else {
          selectedIndex -= 1;
          if (selectedIndex < 0) selectedIndex = this.items.length - 1;
        }
      }
      this.activeItemIndex = selectedIndex;
      const element = this.$refs.item[this.activeItemIndex];
      if (element) element.scrollIntoView();
    },
  },
}
</script>
